import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { BackTopButton } from './styled.js'

function BackTop() {
  const [active, setActive] = useState(false)
  useEffect(() => {
    function toggleBackTop() {
      const scrollTop =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop

      const isActive = scrollTop > 350
      setActive(isActive)
    }

    document.addEventListener('scroll', toggleBackTop)
    return () => {
      document.removeEventListener('scroll', toggleBackTop)
    }
  }, [])

  // Handle window scrolling top.
  function handleClick() {
    const targetTop =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop
    const SCROLL_DURATION = 300 //ms
    const DEFAULT_FPS = 60
    const SCROLL_DISTANCE = (targetTop * (1000 / SCROLL_DURATION)) / DEFAULT_FPS

    function update() {
      const top =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
      if (top <= 0) {
        window.scrollTo(0, 0)
        return
      }
      if (top > 0) {
        window.scrollTo(0, top - SCROLL_DISTANCE)
        window.requestAnimationFrame(update)
      }
    }

    window.requestAnimationFrame(update)
  }

  return (
    <BackTopButton className={active ? 'active' : ''} onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowUp} />
    </BackTopButton>
  )
}

export default BackTop
