import styled from 'styled-components'
import { theme } from '@/styles/theme'

export const FooterTag = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 30px 0;
  background: linear-gradient(
    to right,
    ${theme.footerBackgroundColor} 35%,
    ${theme.footerBackgroundColorHover} 100%
  );
  color: #eeeeee;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    padding: 60px 0;
    flex-direction: row;
    align-items: flex-start;
  }
`

export const InfoWrapper = styled.div`
  padding-top: 20px;

  .info {
    display: flex;
    align-items: center;
    color: #bebebe;
    margin-top: 15px;

    svg {
      font-size: 25px;
      margin-right: 15px;
    }
    p {
      margin: 0;
      font-size: calc(${theme.mainFontSize} - 4px);
    }
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    margin-right: 150px;
  }

  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    margin-right: 250px;
  }

  @media screen and (min-width: ${theme.mediaQueryDesktopWide}) {
    margin-right: 300px;
  }
`

export const SideWrapper = styled.div`
  padding-top: 35px;

  .signature {
    margin: 30px 0 0;
  }
`
