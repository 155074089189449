import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import headerCover from '@/images/header-cover.jpeg'
import avatar from '@/images/avatar.jpeg'
import { STATIC_ROUTER_PATH } from '@/common/constants/routers'

import {
  BannerInfo,
  Logo,
  MenuButton,
  NavbarContainer,
  PageHeader,
  PageNavbar,
  Nav,
} from './styled'

function Header({ active, open, setOpen }) {
  const title = "EngineLin's Blog"
  const subtitle = `
    Hi, 我是 Engine Lin./n
    跟著我一起專研網頁前端技術、訓練自我成長。
  `
  const HEX_OFFICE_WEBSITE = 'https://16n.com.tw'
  const navList = [
    { item: STATIC_ROUTER_PATH.HOME, name: '文章' },
    { item: STATIC_ROUTER_PATH.ABOUTME, name: '關於我' },
    { item: HEX_OFFICE_WEBSITE, name: '合作' },
  ]

  return (
    <PageHeader style={{ backgroundImage: `url(${headerCover})` }}>
      <PageNavbar className={active ? 'active' : ''}>
        <div>
          <NavbarContainer>
            <Logo
              to={STATIC_ROUTER_PATH.HOME}
              title={title}
              alt={title}
              className={active ? 'active' : ''}
            >
              <img src={avatar} alt={title} />
              <h1>{title}</h1>
            </Logo>

            <Nav className={open ? 'open' : ''}>
              <ul>
                {navList.map(({ item, name }) => (
                  <li key={name}>
                    <Link to={item} alt={name} title={name}>
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Nav>

            <MenuButton
              type="button"
              className={active ? 'active' : ''}
              onClick={() => setOpen(!open)}
            >
              <FontAwesomeIcon icon={open ? faTimes : faBars} />
            </MenuButton>
          </NavbarContainer>
        </div>
      </PageNavbar>

      <BannerInfo>
        {subtitle.split('/n').map((t, i) => (
          <h3 key={i}>{t}</h3>
        ))}
      </BannerInfo>
    </PageHeader>
  )
}

Header.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

Header.defaultProps = {
  active: false,
  open: false,
  setOpen: () => {},
}

export default Header
