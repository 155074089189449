import styled from 'styled-components'
import { theme } from '@/styles/theme'
import { Link } from 'gatsby'

export const $transition = '300ms'
export const $mq_mobile = '736px'

export const PageHeader = styled.header`
  position: relative;
  height: 300px;
  background: center no-repeat;
  background-size: cover;
  color: white;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(64, 84, 90, 0.6);
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    height: 350px;
  }
`

export const PageNavbar = styled.nav`
  transition: background ${theme.transitionDuration},
    height ${theme.transitionDuration};
  flex-shrink: 0;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 0;

  & > div {
    padding: 0;
  }

  background: transparent;
  &.active {
    height: 60px;
    background: #5e31dc;
    background: linear-gradient(
      to right,
      ${theme.mainColor} 35%,
      ${theme.mainColorHover} 100%
    );
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    padding: 5px 0;

    & > div {
      padding: 0 10%;
    }
  }
`

export const NavbarContainer = styled.div`
  transition: height ${theme.transitionDuration};
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Nav = styled.nav`
  transition: transform ${$transition}, opacity ${$transition};

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    border-color: transparent;
    transition: border-color ${$transition};

    li {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        display: block;
        height: 100%;
        line-height: 100%;
        padding: 0 15px;
        color: white;

        //Link
        font-size: 16px;
        text-decoration: none;
        transition: color ${$transition};
      }
    }
  }

  @media screen and (max-width: ${$mq_mobile}) {
    transition: unset;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: none;
    opacity: 0;
    z-index: 99;
    background: #5e31dc;
    background: linear-gradient(to right, #5e31dc 35%, #805de3 100%);

    &.open {
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: 'Inter Light';

      li {
        margin: 10px 0;
        padding: 10px;
        a {
          font-size: 22px;
        }
      }
    }
  }
`

export const Logo = styled(Link)`
  transition: height 300ms, margin 300ms;
  display: flex;
  align-items: center;
  height: 50px;
  margin: 10px 0;
  text-align: center;
  text-decoration: unset;

  & > img {
    transition: margin ${$transition};
    margin: 0 5px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    height: 100%;
  }

  & > h1 {
    font-size: 22px;
    margin: 0;
    color: white;
  }
  
  &.active {
    margin: 5px 0;
  }
  
  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    & > img {
      margin: 0 15px;
    }
    
    & > h1 {
      font-size: 24px;
    }
  }
`

export const MenuButton = styled.button`
  transition: padding ${theme.transitionDuration};
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;

  border: 0;
  background: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  padding: 20px;
  color: #fff;
  font-size: 28px;

  display: inline-block;

  &.active {
    padding: 15px 20px;
  }

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    display: none;
  }
`

export const BannerInfo = styled.section`
  position: absolute;
  left: 0;
  top: calc(50% + 30px);
  transform: translateY(-50%);
  display: block;
  width: 100%;
  text-align: center;

  h3 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.1;
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    h3 {
      margin: 15px 0;
      font-size: 26px;
    }
  }
`

export const PageNavbarMini = styled.nav`
  flex-shrink: 0;
  height: 60px;
  padding: 0 10%;

  background: #5e31dc;
  background: linear-gradient(
    to right,
    ${theme.mainColor} 35%,
    ${theme.mainColorHover} 100%
  );

  @media screen and (max-width: ${$mq_mobile}) {
    padding: 0;
  }
`

export const LogoMini = styled(Logo)`
  transition: unset;
  height: 50px;

  & > img {
    margin: 0 5px;
  }
  
  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    & > img {
      margin: 0 15px;
    }
  }
`

export const MenuButtonMini = styled(MenuButton)`
  padding: 15px 20px;
`
