/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({
  slug,
  title,
  description,
  tags,
  hot,
  keys,
  lang,
  meta,
  author,
  cover,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            subtitle
            description
            author
            cover
            keywords
            url
          }
        }
      }
    `
  )

  const metaUrl = slug || site.siteMetadata.url
  const metaTitle = title
    ? `${title} | ${site.siteMetadata.subtitle}`
    : site.siteMetadata.subtitle
  const metaDescription = description || site.siteMetadata.description
  const metaCover = cover
    ? cover.childImageSharp.fluid.originalImg
    : site.siteMetadata.cover
  const metaAuthor = author ? author.nick : site.siteMetadata.author
  const metaKeywords = keys || site.siteMetadata.keywords

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.subtitle}`}
      meta={[
        {
          name: 'author',
          content: 'Engine Lin',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },

        {
          property: `og:local`,
          content: 'zh_TW',
        },
        {
          property: `og:type`,
          content: 'website',
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:image`,
          content: metaCover,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },

        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:site`,
          content: metaUrl,
        },
        {
          name: `twitter:image:src`,
          content: metaCover,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },

        ...meta,
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `zh_TW`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
