import styled from 'styled-components'
import { theme } from '@/styles/theme'

export const Container = styled.article`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;

  main {
    padding: 15px;
    width: 100%;
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    width: ${theme.mediaQueryMobile};
  }

  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    width: ${theme.mediaQueryDesktop};

    main {
      padding: 40px 15px;
    }
  }

  @media screen and (min-width: ${theme.mediaQueryDesktopWide}) {
    width: ${theme.mediaQueryDesktopWide};
  }
`
