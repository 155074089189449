import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import LinkGroup from '@/components/LinkGroup/index.jsx'
import signatureImg from '@/images/signature.png'
import { FooterTag, SideWrapper, InfoWrapper } from './styled.js'

function Footer() {
  return (
    <FooterTag>
      <InfoWrapper>
        <div className="info">
          <FontAwesomeIcon icon={faEnvelope} />
          <p>linengine@gmail.com</p>
        </div>
        <div className="info">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p>Taipei, Taiwan</p>
        </div>
      </InfoWrapper>
      <SideWrapper>
        <LinkGroup />
        <img
          src={signatureImg}
          alt="signature"
          className="signature"
          width="150px"
        />
      </SideWrapper>
    </FooterTag>
  )
}

export default Footer
